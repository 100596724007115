import React, { useEffect } from 'react';

import './trusted-by.scss';

import { partners } from './trusted-by.json';

const TrustedBy = () => {
  useEffect(() => {
    const imgContainer = document.querySelector('.img-container');
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'img-container-animation',
          entry.isIntersecting
        );
      });
    };

    const myObserver1 = new IntersectionObserver(callback, {});
    myObserver1.observe(imgContainer);
  }, []);

  return (
    <section className="trusted-by py-5">
      <div className="container">
        <h2 className="section-title">Trusted By</h2>
        <div className="row img-container">
          {partners.map((logo, i) => (
            <div key={logo.name} className="col-md-4 py-5 text-center">
              <img
                src={logo.imageUrl}
                alt={logo.name}
                width="200"
                className="img-slide-in"
                style={{ '--animation-order': i + 1 }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default TrustedBy;
