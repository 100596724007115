import React from 'react';

import './technology-item.scss';

const TechnologyItem = (props) => {
  const { name, image, description } = props;

  return (
    <div className="technology-item text-center">
      <img src={image} />
      <h3 className="name text-gold fw-bold text-uppercase">{name}</h3>
      <p className="small">{description}</p>
    </div>
  );
};
export default TechnologyItem;
