import React from 'react';

import './technologies.scss';

import { technologies } from './technologies.json';

import TechnologyItem from './TechnologyItem';

import ImgBgContainer from '../../ImgBgContainer';

const Technologies = () => {
  return (
    <ImgBgContainer
      bgImg="/images/surface-dark.jpg"
      overlay="dark"
      className="technologies"
    >
      <div className="container p-custom">
        <h2 className="section-title gold-gradient-text text-uppercase">
          Methods and Technologies
        </h2>

        <div className="row mt-3">
          {technologies.map((technology) => (
            <div key={technology.name} className="col-md-6 col-lg-3">
              <TechnologyItem {...technology} />
            </div>
          ))}
        </div>
      </div>

      {/* <button className="btn btn-primary-outline btn-chevron">
        <i className="bi bi-chevron-down"></i>
      </button> */}
    </ImgBgContainer>
  );
};
export default Technologies;
