import React from 'react';

import './aspect-item.scss';

const AspectItem = (props) => {
  const { title, img, description, index } = props;

  return (
    <div
      className="aspect-item m-3 aspect-slide-in"
      style={{ '--animation-order': index }}
    >
      <img src={img} className="img" />
      <h3 className="title text-gold fw-bold text-uppercase my-3">{title}</h3>
      <p className="small text-justify">{description}</p>
    </div>
  );
};
export default AspectItem;
