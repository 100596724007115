import React, { useEffect } from 'react';

import './recognitions.scss';

import { recognitions } from './recognitions.json';

const Recognitions = () => {
  useEffect(() => {
    const imgContainer = document.querySelector('.recognition-container');
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'recognition-container-animation',
          entry.isIntersecting
        );
      });
    };

    const myObserver1 = new IntersectionObserver(callback, {});
    myObserver1.observe(imgContainer);
  }, []);
  return (
    <section id="associations" className="recognitions">
      <div className="container p-custom">
        <h2 className="section-title mt-5">Associations and Recognitions</h2>
        <div className="row align-items-center recognition-container m-0 p-0">
          {recognitions.map((recognition, i) => (
            <div
              key={recognition.name}
              className="col-md-4 text-center my-3 recognition-slide-in"
              style={{ '--animation-order': i + 1 }}
            >
              <img src={recognition.iconImg} />
            </div>
          ))}
        </div>
      </div>
    </section>
    
  );
};

export default Recognitions;
