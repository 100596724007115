import React, { useEffect } from 'react';

import ImgBgContainer from '../../ImgBgContainer';

import './fundamental-aspects.scss';

import { aspects } from './fundamental-apects.json';
import AspectItem from './AspectItem';

const FundamentalAspects = () => {
  useEffect(() => {
    const aspectContainer = document.querySelector('.aspects-container');
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'aspects-container-animation',
          entry.isIntersecting
        );
      });
    };

    const myObserver = new IntersectionObserver(callback, {});
    myObserver.observe(aspectContainer);
  }, []);

  return (
    <section className="fundamental-aspects ribbon-blue-bg order-gold-tb">
      <div className="container p-5">
        <h2 className="section-title text-gold text-uppercase">
          Fundamental Aspects
        </h2>
        <div className="row justify-content-center mt-5 aspects-container">
          {aspects.map((aspect, i) => (
            <div key={aspect.title} className="col-md-4">
              <AspectItem {...aspect} index={i} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default FundamentalAspects;
