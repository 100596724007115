import React, { useEffect, useState } from 'react';

import './index.scss';

import Page from '../components/layout/Page';
import TrustedBy from '../components/index-page-sections/TrustedBy';
import Products from '../components/index-page-sections/Products';

import { quotes } from './index.json';
import WhoWeAre from '../components/index-page-sections/WhoWeAre';
import WhatWeDo from '../components/index-page-sections/WhatWeDo';
import WhyUs from '../components/index-page-sections/WhyUs';
import FundamentalAspects from '../components/index-page-sections/FundamentalAspects';
import Recognitions from '../components/index-page-sections/Recognitions';
import Testimonials from '../components/index-page-sections/Testimonials';
import Technologies from '../components/index-page-sections/Technologies';
import News from '../components/News';
import { setupLax } from '../utils/lax';
import DownloadApp from '../components/index-page-sections/DownloadApp';

const IndexPage = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setSlideIndex((index) => (index === quotes.length - 1 ? 0 : index + 1));
    }, 8000);
  }, []);

  const handleBannerScroll = () => {
    document.querySelector('#associations').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Page name="Home" className="index">
      <div className="banner-container">
        <video
          id="banner-video"
          autoPlay
          muted
          loop
          className="banner-video-bg"
        >
          <source src="/banner-bg.mp4" type="video/mp4" />
        </video>
        <div className="banner-overlay">
          <div className="banner-content px-3">
            {quotes.map((quote, i) =>
              i === slideIndex ? (
                <blockquote className="quote fw-bold text-center">
                  {quote}
                </blockquote>
              ) : null
            )}
          </div>
          {/* <button
            className="btn btn-primary-outline btn-chevron"
            onClick={handleBannerScroll}
          >
            <i className="bi bi-chevron-down"></i>
          </button> */}
        </div>
      </div>
      <Recognitions />

      <WhoWeAre />
      <WhatWeDo />
      <WhyUs />
      <Products />
      <FundamentalAspects />
      <TrustedBy />
      <Technologies />
      <DownloadApp />
      {/* <News /> */}
    </Page>
  );
};

export default IndexPage;
