import React, { useEffect } from 'react';

import './products.scss';

import Slider from './Slider';

const Products = () => {

    // useEffect(() => {
    //   const productContainer = document.querySelector('.product-container');
    //   const callback = (entries, observer) => {
    //     entries.forEach((entry) => {
    //       entry.target.classList.toggle(
    //         'product-container-animation',
    //         entry.isIntersecting
    //       );
    //     });
    //   };

    //   const myObserver = new IntersectionObserver(callback, {});
    //   myObserver.observe(productContainer);
    // }, []);

    return (
      <section className="products py-5">
        <h2 className="section-title text-uppercase">
          GOLD IS LOVE, ISN'T IT?
        </h2>
        <div className="container">
          <div className="row my-5 product-container">
            <Slider/>
            {/* {products.map((product, i) => (
              <div key={product.name} className="col-md-4">
                <ProductCard
                  {...product}
                  // className={`mx-auto ${i === 1 ? 'center-card' : ''}`}
                  className="mx-auto product-slide-in"
                  index={i}
                />
              </div>
            ))} */}
          </div>

          <div className='row my-5'>
             <div className='col-md-12 d-flex justify-content-center'>
             <a href='/products' className='border-gold text-gold fw-bold px-4 py-2 rounded ts-1'>
                  View All
             </a>
             </div>
          </div>
        </div>
        <div></div>
      </section>
    );
};
export default Products;
