import React, { useEffect } from 'react';

import ImgBgContainer from '../../ImgBgContainer';

import './why-us.scss';

const WhyUs = () => {
  useEffect(() => {
    const p = document.querySelector('.why-us-slide-in-animation');
    const callback1 = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'why-us-slide-in-animation-fadeIn',
          entry.isIntersecting
        );
      });
    };
    const myObserver1 = new IntersectionObserver(callback1, {});
    myObserver1.observe(p);
  }, []);

  return (
    <ImgBgContainer
      bgImg="/images/gold-refinery.jpg"
      overlay="dark"
      className="why-us"
      overlayProps={{
        className: 'overlay',
      }}
    >
      <div className="container py-3">
        <div className="content p-custom">
          <h2 className="section-title gold-gradient-text my-5">Why Us?</h2>
          <p className="text-light text-justify text why-us-slide-in-animation">
            “To shine your brightest light is to be who you truly are.”, Bright
            Gold, accurately consider in the shine of Gold, we host Innovative
            Extraction Methodologies, with varied resources that take care of
            and ensure the finest quality, our Exceptionally trained employees
            brings out the best of the industry. Our intense knowledge and
            excellent industry experience, when put together, makes us a
            superior proposition and keep us at the top of the industry
            competitors.
          </p>
        </div>
      </div>
    </ImgBgContainer>
  );
};
export default WhyUs;
