import React, { useEffect } from 'react';

import ImgBgContainer from '../../ImgBgContainer';

import './what-we-do.scss';

const WhatWeDo = () => {
  useEffect(() => {
    const p = document.querySelector('.what-we-do-slide-in-animation');
    const callback1 = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'what-we-do-slide-in-animation-fadeIn',
          entry.isIntersecting
        );
      });
    };
    const myObserver1 = new IntersectionObserver(callback1, {});
    myObserver1.observe(p);
  }, []);
  return (
    <ImgBgContainer
      bgImg="/images/gold-bars.jpg"
      overlay="light"
      className="what-we-do"
      overlayProps={{
        className: 'overlay',
      }}
    >
      <div className="container py-3">
        <div className="content border-gold p-custom">
          <h2 className="section-title my-5">What We Do?</h2>
          <p className="text-justify text what-we-do-slide-in-animation">
            Have you ever deliberated about how you get to wear those stunning &
            dazzling gold jewellery pieces? Well, gratitude to us, because we
            pass it straight to you. Yes, we are one of the best gold refiners
            using the Aqua Regia methodology! A process where the precious metal
            is extracted from water while shielding our natural resources.
          </p>
        </div>
      </div>
    </ImgBgContainer>
  );
};
export default WhatWeDo;
