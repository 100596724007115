import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination} from 'swiper';
// import Link from "next/link";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import 'swiper/css/pagination';

// import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";

// install Swiper modules
SwiperCore.use([Navigation]);

const features = [
  {
    img: "/images/products/gold-bar-999-1kg.png",
    pera1: "1kg GOLD BAR",
    pera2:
    "999 Gold is the finest quality of fine (Pure) Gold, as the karate of Gold reduces it means the existence of other metals percentage, & we bring to you the purest form."

  },
  {
    img: "/images/products/gold-bar-100g.png",
    pera1: "100g GOLD BAR",
    pera2:
    "The delicacy of 995 indicates that 995 parts of gold are mixed with 5 parts of another metal, which is perfect for your choice of jewelry."

  },
  {
    img: "/images/products/gold-coin-10g.png",
    pera1: "Minted Coins",
    pera2:
    "Minted Coins that we produce are designed to attain and indicate the shapes and designs with perfection to achieve the high quality."

  },
//   {
//     img: "/images/products/Silver_02.png",
//     pera1: "1kg Silver Bar",
//     pera2:
//     "Minted Coins that we produce are designed to attain and indicate the shapes and designs with perfection to achieve the high quality."

//   },
//   {
//     img: "/images/products/Silver_999.png",
//     pera1: "30kg Silver Bar",
//     pera2:
//     "Minted Coins that we produce are designed to attain and indicate the shapes and designs with perfection to achieve the high quality."

//   },
];

const Slider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        // pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={true}
        breakpoints={{
          "640": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "768": {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          "1024": {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {features.map((feature, index) => (
          <SwiperSlide
            key={`${index}-Slidertwo`}
            className="relative swiper-slide p-4"
          >
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 slide-001">
              <div className="mx-auto p-8 grad-001">
                <div>
                  <img
                    src={feature.img}
                    className="img my-3 slide-fade-in"
                    alt="insite"
                  />
                  <div className="flex items-center h-16 text-center justify-center">
                    <p className="product-name text-gold fw-bold">
                      {feature.pera1}
                    </p>
                  </div>
                  <p className="product-description">
                    {feature.pera2}
                  </p>
                  
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Slider;
