import React, { useEffect, useRef } from 'react';
import { setupLax } from '../../../utils/lax';

import './who-we-are.scss';

const WhoWeAre = () => {
  useEffect(() => {
    const p = document.querySelector('.slide-in-animation');
    const callback1 = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'slide-in-animation-fadeIn',
          entry.isIntersecting
        );
      });
    };
    const myObserver1 = new IntersectionObserver(callback1, {});
    myObserver1.observe(p);
  }, []);

  return (
    <section className="who-we-are blue-bg">
      <div className="container py-3">
        <div className="content p-custom">
          <h2 className="section-title gold-gradient-text fade-in-animation my-5">
            Who We Are?
          </h2>
          <p className="text-light text-justify text slide-in-animation">
            Bright Gold Refinery is the scrapper of the finest gold in the
            cosmos, certified by NABL (National Accreditation Board for Testing
            and Calibration Laboratory) and Bureau of Indian Standards (BIS) we
            are committed to bringing out and serving with the superlative. Gold
            is not just a precious metal, it’s an emotion for the populace. That
            is why we refine and manufacture your most desired and sought-after
            Gold Silver jewellery.
          </p>
        </div>
      </div>
    </section>
  );
};
export default WhoWeAre;
